import { combineReducers } from "redux"
import authReducer from "./auth_reducer"
import groupTrainingReducer from "./group_training_reducer"
import privateTrainingReducer from "./private_training_reducer"

const RootReducer = combineReducers({
  auth: authReducer,
  groupTraining: groupTrainingReducer,
  privateTraining: privateTrainingReducer,
})

export default RootReducer
