// AUTH
export const LOADING = "loading"
export const LOADING_CLEAR = "loading_clear"

export const AUTH_USER = "auth_user"
export const UNAUTH_USER = "unauth_user"
export const AUTH_ERROR = "auth_error"

// TRAINING DATES
export const GROUP_TRAINING_DATES = "group_training_dates"
export const GROUP_TRAINING_DATES_ERROR = "group_training_dates_error"

// GROUP TRAININGS
export const GROUP_TRAINING_LOADING = "group_training_loading"
export const GROUP_TRAINING_ALERT_CLEAR = "group_training_alert_clear"

export const GROUP_TRAINING_REG_SUCCESS = "group_training_reg_success"
export const GROUP_TRAINING_REG_ERROR = "group_training_reg_error"

// PRIVATE TRAININGS
export const PRIVATE_TRAINING_LOADING = "private_training_loading"
export const PRIVATE_TRAINING_ALERT_CLEAR = "private_training_alert_clear"
export const PRIVATE_TRAINING_REG_SUCCESS = "private_training_reg_success"
export const PRIVATE_TRAINING_REG_ERROR = "private_trainging_reg_error"
