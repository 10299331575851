import {
  GROUP_TRAINING_LOADING,
  GROUP_TRAINING_ALERT_CLEAR,
  GROUP_TRAINING_DATES,
  GROUP_TRAINING_DATES_ERROR,
  GROUP_TRAINING_REG_SUCCESS,
  GROUP_TRAINING_REG_ERROR,
} from "../types"

export default function(state = { groupDates: [] }, action) {
  switch (action.type) {
    case GROUP_TRAINING_LOADING:
      return { ...state, loading: true }
    case GROUP_TRAINING_ALERT_CLEAR:
      return {
        ...state,
        groupTrainingRegSuccess: "",
        groupTrainingRegError: "",
        loading: false,
      }
    case GROUP_TRAINING_DATES:
      return {
        ...state,
        groupDates: action.payload,
        groupDatesError: "",
        loading: false,
      }
    case GROUP_TRAINING_DATES_ERROR:
      return {
        ...state,
        groupDates: "",
        groupDatesError: action.payload,
        loading: false,
      }
    case GROUP_TRAINING_REG_SUCCESS:
      return {
        ...state,
        groupTrainingRegSuccess: action.payload,
        groupTrainingRegError: "",
        loading: false,
      }
    case GROUP_TRAINING_REG_ERROR:
      return {
        ...state,
        groupTrainingRegSuccess: "",
        groupTrainingRegError: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
