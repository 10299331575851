import {
  LOADING,
  LOADING_CLEAR,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
} from "../types"

export default function(state = { authenticated: false }, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true }
    case LOADING_CLEAR:
      return {
        ...state,
        loading: false,
      }
    case AUTH_USER:
      return { ...state, authenticated: true, authError: "", loading: false }
    case UNAUTH_USER:
      return { ...state, authenticated: false, authError: "", loading: false }
    case AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        authError: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
