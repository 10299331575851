export { default as wrapRootElement } from "./src/redux"

export const onServiceWorkerUpdateReady = () => {
  // Checking for new update - asking for refresh
  const answer = window.confirm(
    `The Cali Reeves website has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
