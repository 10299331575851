import {
  PRIVATE_TRAINING_LOADING,
  PRIVATE_TRAINING_ALERT_CLEAR,
  PRIVATE_TRAINING_REG_SUCCESS,
  PRIVATE_TRAINING_REG_ERROR,
} from "../types"

export default function(state = {}, action) {
  switch (action.type) {
    case PRIVATE_TRAINING_LOADING:
      return { ...state, loading: true }
    case PRIVATE_TRAINING_ALERT_CLEAR:
      return {
        ...state,
        privateTrainingRegSuccess: "",
        privateTrainingRegError: "",
        loading: false,
      }
    case PRIVATE_TRAINING_REG_SUCCESS:
      return {
        ...state,
        privateTrainingRegSuccess: action.payload,
        privateTrainingRegError: "",
        loading: false,
      }
    case PRIVATE_TRAINING_REG_ERROR:
      return {
        ...state,
        privateTrainingRegSuccess: "",
        privateTrainingRegError: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
